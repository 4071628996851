


@use '@angular/material' as mat;
@use 'sass:map';


@mixin theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark-theme: map.get($theme, is-dark);

  formly-field-mat-multicheckbox {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 10px;
    padding: 10px;
  }


  .header-less-tabs .mat-tab-group .mat-tab-header { display: none; }

  formly-field-mat-toggle{
    .mat-slide-toggle-content {
      margin-left: 3rem !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .mat-form-field-label{
    white-space: wrap !important;
  }
  
}
