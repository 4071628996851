@use '@angular/material' as mat;
@use 'sass:map';

@mixin casesearch-theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $is-dark-theme: map.get($theme, is-dark);
  $nav-background-opacity: if($is-dark-theme, 0.2, 0.03);
  $nav-background-focus-opacity: if($is-dark-theme, 0.25, 0.08);

  nfr-case-search {
    flex: 1;
    // width: 100%;
    // display: flex;
    // flex-direction: column;
    // overflow-x: hidden;

    ::ng-deep .mat-icon-button {
      height: 34px !important;
      width: 34px !important;
      line-height: 34px !important;
      font-size: 12px !important;
    }
    ::ng-deep .mat-mini-fab {
      height: 34px !important;
      width: 34px !important;
      line-height: 34px !important;
      font-size: 12px !important;
    }
    ::ng-deep .mat-paginator-icon {
      width: 21px !important;
    }
    ::ng-deep .mat-tooltip {
      font-size: 12px !important;
    }

    .table-container {
      position: relative;
      max-height: calc(100vh - 300px);
      overflow: auto;
    }
    .mat-card{
      border-radius: 0;
    }
  }
  autobot-filter-panel {
    flex: 1;
  }
  flex-sample {
    height: 100vh;
  }
  .flex-spacer {
    flex-grow: 1;
  }
  .mat-cell {
    padding: 0px 8px 0px 0;
  }

  .mat-cell {
    padding: 0px 8px 0px 0;
  }

  .title-bar {
    padding-left: 15px;
    margin-top: 20px;
  }

  .divider {
    margin-left: 30px;
  }

  .box {
    padding: 25px 25px;
    border-radius: 4px;
  }

  .box-dark {
    background: #424242;
    padding: 25px 25px;
    border-radius: 4px;
  }
  .mat-paginator-range-actions {
    display: flex;
    align-items: center;
    width: 40% !important;
  }
  ::ng-deep .mat-tooltip {
    background: red !important;
  }

  @media screen and (max-width: 800px) {
    .desktop-hidden {
      display: initial;
    }
    .mobile-hidden {
      display: none;
    }
    .site-name {
      font-size: 16px;
    }
    .site-logo {
      margin-right: 0.5rem !important;
      height: 30px;
    }
    .date-range-text {
      font-size: 14px;
    }
  }
  @media screen and (min-width: 800px) {
    .desktop-hidden {
      display: none;
    }
    .mobile-hidden {
      display: initial;
    }

    .site-name {
      font-size: 20px;
    }

    .site-logo {
      margin-right: 0.5rem !important;
      height: 40px;
    }
    .date-range-text {
      font-size: 20px;
    }
  }
}
