


@mixin case-progress-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);


  progress-card {
    .mat-progress-bar {
      .mat-progress-bar-fill::after{
        background: whitesmoke !important;
      }
      .mat-progress-bar-buffer{
        background: #8a8efa;
      }
    }

  }
  .mat-list-item {
    margin-bottom: 5px;
  }
}
