@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $is-dark-theme: map.get($theme, is-dark);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $next-theme: mat.define-palette(mat.$red-palette);
  $scrollBackground: if($is-dark-theme,rgba(0, 0, 0, .26),rgba(0, 0, 0, .26));

  ::-webkit-scrollbar {
    width: 12px;
    height: 10px;
  }

  ::-webkit-scrollbar-corner {
    display: none;
  }

  ::-webkit-scrollbar-thumb {
    background: if($is-dark-theme,rgba(0, 0, 0, .26),rgba(0, 0, 0, .26));

    border: 2pt solid transparent;
    border-radius: 3pt;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-track {

    border: 2pt solid transparent;
    background-clip: content-box;
  }

  .custom-scroll-vertical-hover::-webkit-scrollbar-thumb:vertical {
    background: if($is-dark-theme,rgba(255, 255, 255, .4),rgba(0, 0, 0, .26));
    border: none;
    border-radius: 0;
  }

  .custom-scroll-vertical-hover::-webkit-scrollbar-track:vertical {
    background:  if($is-dark-theme,rgba(255, 255, 255, .4),rgba(0, 0, 0, .26));
    border: none;
  }
}
