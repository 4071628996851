
body { font-family: 'Open Sans'; font-size: 12px; background: #fff;}
a, a:active, .detail-item a span { color: #2E5371; text-decoration: underline;}
a:hover, a:hover span{ color:#102536; text-decoration: none;}
.links{ color: #2E5371 !important; text-decoration: underline;}
.container, .mat-drawer-container{background: #fff;}
.mat-form-field, .mat-list-item, .mat-list-option, .mat-list-base .mat-list-item {font-size: 12px; font-family: 'Open Sans';}
.mat-button-toggle, .mat-card, .mat-tab-group, .mat-tab-label, .mat-tab-link, .mat-table, .mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button, .mat-flat-button, .mat-fab, .mat-mini-fab, .mat-expansion-panel-header, .mat-checkbox, .mat-select-value, .mat-radio-button, .mat-select { font-family: 'Open Sans' !important;}
.mat-form-field-appearance-legacy .mat-form-field-infix {
font-size: 12px;
font-weight: normal;
color: #000;
text-shadow: none;
opacity: 1;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker
{
color: #f00 !important;
}
.mat-toolbar-row,
.mat-toolbar-single-row {
padding-left: 45px !important;
height: 53px;
}


.mat-button-toggle, .mat-card{ font-family: 'Open Sans';}


/*Table*/
.mat-row:hover .mat-cell {
  background-color: #e5ecf8;
  }
  .highlightTableColor {
  background-color: #e5ecf8;
  }
  .table,
  .mat-cell,
  .mat-footer-cell {
  font-size: 12px !important;
  }
  .table {
  border: 1px solid #d3d3d3;
  }
  .table > thead {
  vertical-align: middle;
  }
  tr.mat-header-row {
  min-height: auto !important;
  }

  th.mat-header-cell:first-of-type,
  td.mat-cell:first-of-type,
  td.mat-footer-cell:first-of-type {
  padding-left: 17px !important;
  }
  th.mat-header-cell,
  td.mat-cell,
  td.mat-footer-cell {

  vertical-align: middle;
  }
  th.mat-header-cell,
  td.mat-cell,
  td.mat-footer-cell {
  border-color: #d3d3d3;
    }
  .table > :not(:last-child) > :last-child > * {
  border-bottom-color: #d3d3d3;
  border-bottom-width: 1px;
  border-style: solid;
  }
  .mat-header-cell{ color: #000 !important; font-weight: 600 !important;}
  /*mat card*/
  .custom-material-cont { background-color: #fff !important;}
  .custom-material-cont .mat-card-header-text{ margin: 0 3px;}

/* Modal Dialog*/

.custom-dialog .mat-dialog-title {
    background-color: #ebebeb;
    margin: -24px !important;
    padding: 10px 10px 10px 20px !important;
    text-align: left !important;
    font-weight: 600;
  }

  .custom-dialog h2 {
    font-size: 16px;
  }
.resize-no textarea{ resize: none !important;}
  .custom-dialog .mat-dialog-content{
    margin: 39px 0px 0px 0px !important;
  padding: 0px 5px !important;
  overflow-x: hidden;
  }
  .custom-dialog .dialog-container .btn-container {
  margin: 0 auto !important;
  padding: 8px 8px 25px 8px !important;
  }
  .custom-dialog  .dialog-container .btn-container .mat-flat-button,
  .custom-dialog .dialog-container .btn-container .mat-raised-button {
  min-width: 141px !important;
  }

  .mat-body, .mat-body-1, .mat-typography .mat-body, .mat-typography .mat-body-1, .mat-typography {font:400 14px/20px 'open sans' !important;}

.mt-20{ margin-top: 20px;}
.mt-18{ margin-top: 18px;}


  /*Font*/

.mat-form-field .mat-form-field-infix{
/*change color of label  */
font-size: 13px !important;
color: #000 !important;
transform: translateY(0%);
padding-bottom: 5px;
}

.mat-focused .mat-form-field{
/*change color of label*/
font-size: 13px !important;
color: #2e5371 !important;
transform: translateY(0%);
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  font-size: 13px !important;
  font-weight: normal;
  color: #000;
  text-shadow: none;
  opacity: 1;
  }
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float
.mat-form-field {
text-shadow: none !important;
opacity: 1;
font-family: 'open sans' !important;
font-weight: normal;
text-wrap: wrap;
line-height: 16px;
transform: translateY(0%);
}

.mat-form-field-underline {
/*change color of underline*/
background-color: #ccc !important;
}

.mat-form-field-ripple {
/*change color of underline when focused*/
background-color: #2e5371 !important;
}

.mat-form-field label.mat-radio-label {
color: #000 !important;
font-family: 'Open Sans' !important;
opacity: 1;
font-size: 12px !important;
}
.app-background{ background: #fff !important;}
app-root>router-outlet+.main-content, app-root>router-outlet+app-main-sidenav{ margin-top: 0px !important;}

.mat-toolbar.page-heading.mat-toolbar-single-row{ height: 50px !important;}
.mat-toolbar-row, .page-heading.mat-toolbar-single-row{ padding-left:20px !important ;}
.mat-toolbar, .mat-toolbar h1, .mat-toolbar h2, .mat-toolbar h3, .mat-toolbar h4, .mat-toolbar h5, .mat-toolbar h6{font:500 20px / 32px 'open sans' !important;}
.mat-toolbar-row, .mat-toolbar-single-row{ padding-left: 20px !important;}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar{ background-color: #214563 !important;}

lib-template-editor #wrapper, lib-template-editor .left-nav{ height: calc(100vh - 70px) !important;}
.example-tree-node .example-tree-node{ padding-left: 10px !important;}
