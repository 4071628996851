@use '@angular/material' as mat;
@use 'sass:map';


@mixin case-editor-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark-theme: map.get($theme, is-dark);

   autobot-case-entry {


    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;

     .tab-option {
       color: whitesmoke;
     }

    .dark-field {

    }

     .frame-shadow {
      box-shadow:  5px 7px 18px -5px rgb(0 0 0 / 50%);
    }

    .dynamic-container {
      max-width: 1400px;
      width: 100%;
      margin: 0 auto;
    }

    .editor-toolbar {
      background-color: transparent !important;
    }

     .tab-nav {
       color: white;
     }

    .selected-tab-nav {
      background-color: cornflowerblue;
    }
    .mat-card-header .mat-card-title {
      font-size: 16px;
    }

    .sidenav-content {
      height: calc(100vh - 120px);
      display: flex;
      flex-direction: column;
    }

    .left-filter-nav {
      border-radius: 0.2rem !important;

      height: fit-content;
      max-width: 250px;
      min-width: 250px;

      &-narrow {
        max-width: 250px;
        min-width: 250px;
      }

      margin: 3rem 1.5rem !important;

    }

    .main-container {
      flex-direction: column;
      box-sizing: border-box;
      display: flex;
      margin: 3rem !important;
      width: 100%;
    }

    .mat-column-id {
      word-wrap: break-word !important;
      white-space: unset !important;
      flex: 0 0 15% !important;
      width: 20% !important;
      overflow-wrap: break-word;
      word-wrap: break-word;

      word-break: break-word;

      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
    }

    .mat-column-name {
      word-wrap: break-word !important;
      white-space: unset !important;
      flex: 0 0 15% !important;
      width: 20% !important;
      overflow-wrap: break-word;
      word-wrap: break-word;

      word-break: break-word;

      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
    }
    .mat-column-actions {
      word-wrap: break-word !important;
      white-space: unset !important;
      flex: 0 0 15% !important;
      width: 10% !important;
      overflow-wrap: break-word;
      word-wrap: break-word;

      word-break: break-word;

      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
    }
    .mat-column-seq {
      word-wrap: break-word !important;
      white-space: unset !important;
      flex: 0 0 15% !important;
      width: 5% !important;
      overflow-wrap: break-word;
      word-wrap: break-word;

      word-break: break-word;

      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
    }

    .mat-column-parser {
      word-wrap: break-word !important;
      white-space: unset !important;
      flex: 0 0 15% !important;
      width: 15% !important;
      overflow-wrap: break-word;
      word-wrap: break-word;

      word-break: break-word;

      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
    }


    @media screen and (max-width: 800px) {


    }
    @media screen and (min-width: 800px) {
      .desktop-hidden {
        display: none;
      }
      .mobile-hidden {
        display: initial;
      }

      .site-name {
        font-size: 20px;
      }

      .site-logo {
        margin-right: .5rem !important;
        height: 40px;
      }
    }


  }

   autobot-h {

        background-color: black;
        box-shadow: 3px 0 6px rgba(0,0,0,.24);
        color: whitesmoke;

      .tab-option {
        color: whitesmoke;
      }
      .quick-action-background {
        background-color: #212121;;
      }
      .number-circle {
        border-radius: 50%;
        width: 36px;
        height: 36px;
        padding: 5px;

        background: #fff;
        border: 2px solid #666;
        color: #666;
        text-align: center;


      }
    }

   autobot-case-global {
      .mat-tab-label-container {
        display: none !important;
        flex-grow: 1;
        overflow: hidden;
        z-index: 1;
      }

    }

  case-form-settings {
    .mat-snack-bar-top {
      margin-top: 75px !important;
    }
  }

  .form-container {
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
    height: 100% !important;
    width: 100% !important;

  }


}


