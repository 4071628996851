@use '@angular/material' as mat;
@use 'sass:map';


@mixin landing-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark-theme: map.get($theme, is-dark);


  autobot-landing {


    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;

    .mat-list-base {
      padding-top: 0px !important;
      display: block;
      -webkit-tap-highlight-color: transparent;
    }


  }
}

