@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  app-navbar {
    color: mat.get-color-from-palette($primary, default-contrast);

    .navbar, .navbar-header {
      background: mat.get-color-from-palette($primary, 800);

      & a:hover, & a:focus {
        background: rgba(0, 0, 0, 0.4);
        color: mat.get-color-from-palette($accent, 200);
      }

      .navbar-header-item-selected {
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }
}
