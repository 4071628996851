@use '@angular/material' as mat;
@use 'sass:color';
@use 'sass:map';
@use '../../../styles/constants';

@mixin form-runner-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  case-form-runner {
    .case-viewer-left-sidenav {
      color: mat.get-color-from-palette($primary, default-contrast);
      background: mat.get-color-from-palette($primary);
    }

    .case-viewer-inner-container {
      .mat-tab-label {
        width: 100%;
        background: mat.get-color-from-palette($primary, 800);
        color: mat.get-color-from-palette($accent, A100);
        justify-content: left;
        opacity: 1;
      }

      form-guide {
        .area-selected {
          background: rgba(mat.get-color-from-palette($accent, 300), 1);

          .mat-list-item-text-color {
            color: mat.get-color-from-palette($accent, 900);

            &:hover {
              color: mat.get-color-from-palette($accent, 900);
            }
          }

          &:hover,
          &:focus {
            background: rgba(mat.get-color-from-palette($accent, 300), 1);
          }
        }

        .area-item {
          color: mat.get-color-from-palette($primary, default-contrast);
        }
      }
    }
  }
 
}
.case-viewer-right-sidenav{
  width: 239px; }

.mat-focused .mat-form-field-required-marker {
  color: #214563 !important;
}
.triage-elig .error-message{margin-left:9px;position:relative;top:-10px;text-transform:lowercase;font-size: 10px !important; color: #d72638 !important;}
.triage-elig .error-message:first-letter{text-transform:capitalize;}
.triage-elig .eligbilitydatefield{ width:100%;}
.triage-elig .mat-card-title.panel-title{margin-left:0.5rem !important;}
@media screen and (max-width:1700px){

  .triage-elig .eligbilitydatefield{ padding-left:0.6rem; }
}
@media screen and (min-width:1701px) and (max-width:1920px) {
  .triage-elig .eligbilitydatefield{ padding-left:0.8rem; }
}

.page-wrapper{
  height: calc(100% - 53px);
}

.page-wrapper .page-scroll.triage-elig{
  height: 100% !important;
  margin-bottom: 20px;
}