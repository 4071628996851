@use '@angular/material' as mat;
@use 'sass:map';


@mixin new-call-theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $is-dark-theme: map.get($theme, is-dark);





  .account-type-text {
    color:  mat.get-color-from-palette($accent, 500);
  }





}
