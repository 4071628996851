@use '@angular/material' as mat;
@use 'sass:color';
@use 'sass:map';
@use '../../styles/constants';

@mixin layout-theme($theme) {
  $primary: map.get($theme, primary);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $is-dark-theme: map.get($theme, is-dark);
  $nav-background-opacity: if($is-dark-theme, 0.2, 0.03);
  $nav-background-focus-opacity: if($is-dark-theme, 0.25, 0.08);
  $accent: map.get($theme, accent);

  .nfr-viewer-sidenav {
    color: mat.get-color-from-palette($primary, default-contrast);
    background: mat.get-color-from-palette($primary);

    .nfr-viewer-nav-content {
      &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.26);
      }
    }
    .mat-list-item {
      color: mat.get-color-from-palette($primary, default-contrast);
    }
    .active-list-item {
      //make it lighter
      background: rgba(mat.get-color-from-palette($accent, 50), 0.15);
      .mat-list-item-content {
        color: map.get($foreground, secondary-text);
        &:hover {
          color: mat.get-color-from-palette($foreground, text);
        }
      }
    }

    .active-list-item:hover, .active-list-item:focus {
      background: rgba(mat.get-color-from-palette($accent, 100), 0.15);
    }
  }
}

@media (max-width: constants.$small-breakpoint-width) {
  .nfr-viewer-sidenav {
    .nfr-viewer-nav-content {
      background: none;
    }
  }

  .mat-drawer {
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.26);
    }
  }
}
