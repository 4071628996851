
@use '@angular/material' as mat;
@use 'sass:color';
@use 'sass:map';
@use '../styles/constants';

@import 'modules/form-runner/form-runner-theme';
@import 'modules/case-actions/case-actions.theme';
@import 'modules/case-nav/case-nav-theme';
@import 'modules/case-progress/case-progress-theme';
@import 'modules/case-notes/case-notes-theme';
@import 'modules/custom-form-runner/custom-form-runner';

@mixin case-main-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .case-viewer-sidenav{
    color: mat.get-color-from-palette($primary, default-contrast);
    background: mat.get-color-from-palette($primary);
  }
  @include case-notes-theme($theme);
  @include case-progress-theme($theme);
  @include form-runner-theme($theme);
  @include case-nav-theme($theme);
  @include custom-form-theme($theme);
  @include case-actions-theme($theme);
}
