@use '@angular/material' as mat;
@use 'sass:map';



@mixin template-fields-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark-theme: map.get($theme, is-dark);

  .followup-provider{
    .testing{
        width: 48%;
    }
}

  .testing {
    min-width: 28%;
    margin: 1%;
  }

  .field-30 {
    min-width: 32%;
    margin: 1%;
  }
  .field-50 {
    min-width: 48%;
    margin: 1%;
  }
  .field-50-slim-y {
    min-width: 48%;
    margin-left: 1%;
    margin-right: 1%;
  }

  .field-100 {
    min-width: 99%;
    margin: 1%;
  }
  .field-49 {
    min-width: 49%;
  }
}
