/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@use 'app-theme';

@import 'styles/bootstrap/bootstrap.css';
@import 'styles/bootstrap/bootstrap-reboot.css';

  @font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(../../../libs/shared/assets/fonts/MaterialIcons-Regular.ttf) format('truetype'),
         url(../../../libs/shared/assets/fonts/MaterialIcons-Regular.woff2)format('woff2'),
         url(../../../libs/shared/assets/fonts/MaterialIcons-Regular.eot);
  }

  .material-icons{
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
  }

// Include material app styles.
@include mat.core();
$blue-black-palette: (
  50: #e3f2fd,
  100: #bbdefb,
  200: #90caf9,
  300: #64b5f6,
  400: #2E5371,
  500: #214563,
  600: #1e88e5,
  700: #1976d2,
  800: #183248,
  900: #102536,
  A100: #82b1ff,
  A200: #448aff,
  A400: #2979ff,
  A700: #2962ff,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: white,
    A700: white,
  )
);

$grey-white-palette: (
  50: #fafafa,
  100: #f5f5f5,
  200: #eeeeee,
  300: #E8E8E8,
  400: #bdbdbd,
  500: #9e9e9e,
  600: #757575,
  700: #616161,
  800: #424242,
  900: #000000,
  A100: #ffffff,
  A200: #eeeeee,
  A400: #bdbdbd,
  A700: #616161,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: white,

  )
);
$crimson-palette: (
  50: #ffebef,
  100: #ffcdc5,
  200: #f0999f,
  300: #e77179,
  400: #f24e58,
  500: #f83a40,
  600: #e9313e,
  700: #d72638,
  800: #ca1e30,
  900: #bb0d24,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  )
);

// Define the light theme.
$bluesky-primary: mat.define-palette($blue-black-palette);
$bluesky-accent: mat.define-palette($grey-white-palette, 300, 500);
$bluesky-warn: mat.define-palette($crimson-palette, 700);

$_theme: mat.define-light-theme((
  color: (
    primary: $bluesky-primary,
    accent: $bluesky-accent,
    warn: $bluesky-warn,
  )
  ));
@include mat.all-component-themes($_theme);
@include app-theme.theme($_theme);

body { margin: 0; font-family: 'Open Sans', "Helvetica Neue", sans-serif; font-size: 12px; }
