@use 'sass:map';
@use '@angular/material' as mat;

@mixin case-nav-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .case-nav-container{
    .mat-tab-label {
      width: 100%;
      color: mat.get-color-from-palette($accent, A100);
      justify-content: left;
      opacity: 1;
      background: rgba(mat.get-color-from-palette($primary, 800), 1);
      
    }
    .navbar-item{
      color: mat.get-color-from-palette($primary, default-contrast);
    }
    .navbar-item-selected {
      //make it lighter
      background: rgba(mat.get-color-from-palette($accent, 300), 1);
      .mat-list-item-content {
        color: mat.get-color-from-palette($accent, 900);
        &:hover {
          color: mat.get-color-from-palette($foreground, text);
        }
      }
    }

    .navbar-item-selected:hover, .navbar-item-selected:focus {
      background: rgba(mat.get-color-from-palette($accent, 100), 1);
    }
  }
    .nav-container {
      flex-direction: column;
      box-sizing: border-box;
      display: flex;
      place-content: flex-start space-between;
      align-items: flex-start;
      height: 100% !important;

      color: black;

      .menu-list {
        flex: 1;
        justify-content: normal;
      }
      & a:hover,
      & a:focus {
        background: rgba(0, 0, 0, 1);
        color: mat.get-color-from-palette($primary);
      }
      .navbar-item-selected {
        background: rgba(0, 0, 0, 1);
      }
      .link-text {
        margin-left: 15px;
      }
      .label {
        margin-left: 16px;
      }
      .mat-list-base {
        padding-top: 0px;
      }

      .icon {
        padding: 0 10px 0 0;
        text-align: center;
        vertical-align: middle;
      }
    }

    .theme-nav-container {
      flex-direction: column;
      box-sizing: border-box;
      display: flex;
      place-content: flex-start space-between;
      align-items: flex-start;
      height: 100% !important;
      padding: 1rem !important;

      &-gradient {
        background: rgb(2, 0, 36);
        background: linear-gradient(
          163deg,
          rgba(2, 0, 36, 1) 0%,
          rgba(1, 33, 92, 1) 26%,
          rgba(0, 212, 255, 1) 100%
        );

        .header-text {
          font-size: 20px;
          font-weight: 400;
          color: rgb(219, 216, 216);
          line-height: 28px;
        }
        .menu-item {
          background-color: transparent;
          cursor: pointer;
          border: none;
          border-radius: 4px;
          padding: 8px 8px 3px;
          user-select: none;
          width: 100%;
          line-height: 36px;
          color: whitesmoke;

          display: flex;
          align-items: center;
          flex-direction: row;
          flex: 1;
        }
        .menu-item:hover {
        }
        .menu-item:active {
        }
      }
      &-plain {
        background: white;

        .header-text {
          font-size: 20px;
          font-weight: 400;
          color: black;
          line-height: 28px;
        }
        .menu-item {
          background-color: transparent;
          cursor: pointer;
          border: none;
          border-radius: 4px;
          padding: 8px 8px 3px;
          user-select: none;
          width: 100%;
          line-height: 36px;
          color: black;

          display: flex;
          align-items: center;
          flex-direction: row;
          flex: 1;
        }
        .menu-item:hover {
        }
        .menu-item:active {
        }
      }
      &-black {
        background-color: rgb(15, 23, 42);

        .mat-raised-button {
          background-color: #fcdb36 !important;
          color: black !important;
          font-weight: 600;
          font-size: 16px;
        }

        .header-text {
          font-size: 20px;
          font-weight: 400;
          color: rgb(219, 216, 216);
          line-height: 28px;
        }
        .menu-item {
          cursor: pointer;
          border: none;
          border-radius: 4px;
          padding: 8px 8px 3px;
          user-select: none;
          width: 100%;
          line-height: 36px;
          font-size: 13px;
          font-weight: 500;
          color: rgb(255, 255, 255);
          opacity: 0.8;

          display: flex;
          align-items: center;
          flex-direction: row;
          flex: 1;
        }
        .menu-item:hover {
        }
        .menu-item:active {
        }
      }
    }
  }
