@use '@angular/material' as mat;
@use 'sass:map';

@use 'libs/autobot/shared/landing/src/theme' as landing;
@use 'libs/autobot/layout/src' as layout;
@use 'libs/forms/pages/src/lib/styles' as forms;
@use 'libs/autobot/formly/src/lib/formly-styles';
@use 'libs/autobot/styles/src/autobot-theme' as autobot;
@use 'libs/nfr/pages/src/lib/styles' as nfr;
@use 'libs/nfr/data/session/src/lib/modules/call' as call;
@use 'styles/scroll-bar-theme';

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $next-theme: mat.define-palette(mat.$red-palette);

  .app-background {
    background: mat.get-color-from-palette($background, background);
  }
  app-home {
    flex: 1;
  }
  app-about {
    flex: 1;

    .mat-pseudo-checkbox {
      display: none !important;
    }
  }

  .primary-header {
    // background: mat.get-color-from-palette($primary);

    h1 {
      color: mat.get-color-from-palette($primary, default-contrast);
    }
  }

  .ngx-datatable.material.single-selection .datatable-body-row.active:hover,
  .ngx-datatable.material.single-selection
    .datatable-body-row.active:hover
    .datatable-row-group,
  .ngx-datatable.material.multi-selection .datatable-body-row.active:hover,
  .ngx-datatable.material.multi-selection
    .datatable-body-row.active:hover
    .datatable-row-group,
  .ngx-datatable.material.multi-click-selection
    .datatable-body-row.active:hover,
  .ngx-datatable.material.multi-click-selection
    .datatable-body-row.active:hover
    .datatable-row-group {
    background-color: mat.get-color-from-palette($primary);
    color: #fff;
  }

  .footer {
    background: mat.get-color-from-palette($primary);
    color: mat.get-color-from-palette($primary, default-contrast);
  }

  lib-sessions-home {
    flex: 1;
  }

  .smooth-border {
    border: 1px solid lightgrey;
  }

  .mat-tooltip {
    font-size: 14px !important;
    max-width: auto !important;
  }
  .mat-radio-button {
    margin: 3px;
  }

  @include autobot.autobot-theme($theme);

  @include layout.footer-theme($theme);
  @include layout.horizontalmenu-theme($theme);
  @include layout.navbar-theme($theme);
  @include layout.sidenav-theme($theme);

  @include forms.forms-theme($theme);
  @include forms.template-fields-theme($theme);
  @include forms.templates-base-theme($theme);

  @include formly-styles.theme($theme);
  @include landing.landing-theme($theme);
  @include nfr.case-main-theme($theme);
  @include nfr.case-editor-theme($theme);
  @include nfr.dashboard-theme($theme);
  @include nfr.new-call-theme($theme);
  @include nfr.casesearch-theme($theme);
  @include nfr.layout-theme($theme);
  @include call.new-call-theme($theme);
  @include scroll-bar-theme.theme($theme);
}
