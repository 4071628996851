@use '@angular/material' as mat;
@use 'sass:map';


@mixin templates-base-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark-theme: map.get($theme, is-dark);

  autobot-template-creator {
    flex: 1;
  }
  lib-template-home {


    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;

    .frame-shadow {
     box-shadow:  5px 7px 18px -5px rgb(0 0 0 / 50%);
    }

    .dynamic-container {
      max-width: 1400px;
      width: 100%;
      margin: 0 auto;
    }

    .editor-toolbar {
      background-color: transparent !important;
    }


    .mat-card-header .mat-card-title {
      font-size: 16px;
    }


    .sidenav-content {
      height: calc(100vh - 120px);
      display: flex;
      flex-direction: column;
    }

    .left-filter-nav {
      border-radius: 0.2rem !important;

      height: fit-content;
      max-width: 250px;
      min-width: 250px;

      &-narrow {
        max-width: 250px;
        min-width: 250px;
      }

      margin: 3rem 1.5rem !important;

    }

    .main-container {
      flex-direction: column;
      box-sizing: border-box;
      display: flex;
      margin: 3rem !important;
      width: 100%;
    }

    .mat-column-id {
      word-wrap: break-word !important;
      white-space: unset !important;
      flex: 0 0 15% !important;
      width: 20% !important;
      overflow-wrap: break-word;
      word-wrap: break-word;

      word-break: break-word;

      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
    }

    .mat-column-name {
      word-wrap: break-word !important;
      white-space: unset !important;
      flex: 0 0 15% !important;
      width: 20% !important;
      overflow-wrap: break-word;
      word-wrap: break-word;

      word-break: break-word;

      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
    }
    .mat-column-actions {
      word-wrap: break-word !important;
      white-space: unset !important;
      flex: 0 0 15% !important;
      width: 10% !important;
      overflow-wrap: break-word;
      word-wrap: break-word;

      word-break: break-word;

      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
    }
    .mat-column-seq {
      word-wrap: break-word !important;
      white-space: unset !important;
      flex: 0 0 15% !important;
      width: 5% !important;
      overflow-wrap: break-word;
      word-wrap: break-word;

      word-break: break-word;

      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
    }

    .mat-column-parser {
      word-wrap: break-word !important;
      white-space: unset !important;
      flex: 0 0 15% !important;
      width: 15% !important;
      overflow-wrap: break-word;
      word-wrap: break-word;

      word-break: break-word;

      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
    }


    @media screen and (max-width: 800px) {


    }
    @media screen and (min-width: 800px) {
      .desktop-hidden {
        display: none;
      }
      .mobile-hidden {
        display: initial;
      }

      .site-name {
        font-size: 20px;
      }

      .site-logo {
        margin-right: .5rem !important;
        height: 40px;
      }
    }


  }
  lib-template-editor {
    flex: 1;

    .right-preview-sidenav {
      border-radius: 0.2rem !important;
      height: fit-content;
      margin: 0rem 0.5rem !important;
      max-width: 950px;
      min-width: 450px


    }

    .mat-radio-label {
      margin: 4px;
      padding: 10px !important;
    }
    .mat-tab-group {
      flex-direction: row;
    }

    .mat-tab-header {
      border-bottom: none;
    }

    .mat-tab-header-pagination {
      display: none !important;
    }

    .mat-tab-labels {
      flex-direction: column;
    }
    #wrapper{
      height: calc(100vh - 186px) !important;

      min-height: 100%;
    }
    .mat-ink-bar {
      height: 100%;
      left: 98% !important;
    }

    .mat-tab-body-wrapper {
      flex: 1 1 auto;
    }

    .left-sidenav {
      width: 300px;
    }

    .right-sidenav {
      width: 300px;
    }

    .nav-toggle {
      height: 80px;
      position: absolute;
      z-index: 800;

      &-left {
        margin-left: -10px;
        border-radius: 1px 30px 30px 0px;
      }

      &-right {
        margin-right: -10px;
        border-radius: 30px 1px 1px 30px;
        right: 10px;
        top: 90px
      }
    }

    .left-toggle {
      padding: 0;
      text-align: center;
      margin: 0;
      width: 160px;
      height: 40px;
      /* background: #FF931E; */
      z-index: 15;
      border-radius: 5px 5px 0 0;
      -moz-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      -webkit-transform: rotate(90deg);
      transform-origin: bottom left;
      position: fixed;
      left: 0

    }

    .general-flush-toggle {
      font-size: 20px;
      padding: 0;
      text-align: center;
      margin: 0;
      width: 160px;
      height: 40px;
      /* background: #FF931E; */
      z-index: 15;
      /* border-radius: 5px 5px 0 0; */
      -moz-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
      /* transform-origin: bottom left; */
      position: fixed;
      left: -60px;
      top: 200px;

    }

    .tabs-flush-toggle {
      font-size: 20px;
      padding: 0;
      text-align: center;
      margin: 0;
      width: 160px;
      height: 40px;
      /* background: #FF931E; */
      z-index: 15;
      /* border-radius: 5px 5px 0 0; */
      -moz-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
      /* transform-origin: bottom left; */
      position: fixed;
      left: -60px;
      top: 380px;

    }

    .areas-flush-toggle {
      font-size: 20px;
      padding: 0;
      text-align: center;
      margin: 0;
      width: 160px;
      height: 40px;
      /* background: #FF931E; */
      z-index: 15;
      /* border-radius: 5px 5px 0 0; */
      -moz-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
      /* transform-origin: bottom left; */
      position: fixed;
      left: -60px;
      top: 560px;

    }

    .fields-flush-toggle {
      font-size: 20px;
      padding: 0;
      text-align: center;
      margin: 0;
      width: 160px;
      height: 40px;
      /* background: #FF931E; */
      z-index: 15;
      /* border-radius: 5px 5px 0 0; */
      -moz-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
      /* transform-origin: bottom left; */
      position: fixed;
      left: -60px;
      top: 740px;

    }

    .text-orient-vertical {
      text-orientation: mixed;
      writing-mode: vertical-rl;
    }

    .right-nav {
      width: 20%;
      height: fit-content;
    }

    .left-nav {
      width: 20%;
      min-width: 300px;
      height: calc(100vh - 180px);
    }

  }
  editor-list-item {

    .item {
      flex-direction: row;
      box-sizing: border-box;
      display: flex;
      place-content: center space-between;
      align-items: center;
      cursor: pointer;
      border-radius: 0.3rem !important;
      padding: 1.5rem !important;
      width: 100%;

    }

    .item:hover {
      background: rgba(44, 44, 44, 0.04);
    }

    .no-hover {
      position: relative;
      opacity: 0.65 !important;
      display: inline-block;
    }

    .no-hover::before {
      content: '';
      background-color: transparent;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 60;
    }

  }
  .mat-expansion-panel-content {
    font: 400 20px / 20px Open Sans, "Helvetica Neue", sans-serif;
    letter-spacing: normal;
  }

  .preview-frame-shadow {
    box-shadow: 5px 7px 20px -5px rgb(0 0 0 / 50%);
  }




  .form-container {
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
    height: 100% !important;
    width: 100% !important;

  }
}


