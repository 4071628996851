@use '@angular/material' as mat;
@use 'sass:map';




@mixin forms-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark-theme: map.get($theme, is-dark);

  .nav-container {
    height: 100vh;
  }

  .flex-spacer {
    flex-grow: 1;
  }

  autobot-rule-editor {
    flex: 1;
  }
  lib-behaviors {
    flex: 1;
  }
}


