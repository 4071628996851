@use '@angular/material' as mat;
@use 'sass:map';


@mixin new-call-theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $is-dark-theme: map.get($theme, is-dark);
  $nav-background-opacity: if($is-dark-theme, 0.2, 0.03);
  $nav-background-focus-opacity: if($is-dark-theme, 0.25, 0.08);

  nfr-call-initiation{
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;

    ::ng-deep .mat-icon-button {
      height: 34px !important;
      width: 34px !important;
      line-height: 34px !important;
      font-size: 12px !important;
    }
    ::ng-deep .mat-mini-fab {
      height: 34px !important;
      width: 34px !important;
      line-height: 34px !important;
      font-size: 12px !important;
    }
    ::ng-deep .mat-paginator-icon {
      width: 21px !important;
    }
    ::ng-deep .mat-tooltip {
      font-size: 12px !important;
    }

    .table-container {
      position: relative;
      max-height: calc(100vh - 300px);
      overflow: auto;

    }
    // @keyframes spin {
    //   from { transform: rotate(0deg); }
    //   to {transform: rotate(360deg);}
    // }

    // .creating:before {
    //   content: '';
    //   animation: spin .8s linear infinite;
    // }

  }
  autobot-filter-panel {
    flex: 1;
  }
  flex-sample {
    height: 100vh;
  }
  .flex-spacer {
    flex-grow: 1;
  }

  .box {
    padding: 25px 25px;
    border-radius: 4px;
  }

  .account-type-text {
    color:  mat.get-color-from-palette($accent, 500);
  }
}
