@use '@angular/material' as mat;
@use 'sass:map';
@import '@angular/cdk/overlay-prebuilt.css';


@mixin autobot-theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $next-theme: mat.define-palette(mat.$red-palette);

  .app-background {
    background: mat.get-color-from-palette($background, background);
  }
  app-home {
    flex:1;
  }
  app-about {
    flex: 1;

    .mat-pseudo-checkbox{ display: none !important; }
  }



}
