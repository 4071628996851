@mixin custom-form-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .flex-spacer {
    flex-grow: 1;
  }

  custom-form-layout {
    background: transparent;
    .case-viewer-left-sidenav {
      color: mat.get-color-from-palette($primary, default-contrast);
      background: mat.get-color-from-palette($primary, 900);

      .case-viewer-inner-container {
         .mat-tab-label {
          width: 100%;
          background: mat.get-color-from-palette($primary, 800);
          color: mat.get-color-from-palette($accent, A100);
          justify-content: left;
          opacity: 1;
        }
        .mat-nav-list a {color: mat.get-color-from-palette($primary, default-contrast); }

        .menu-selected {
          background: rgba(mat.get-color-from-palette($accent, 300), 1) !important;
          color: mat.get-color-from-palette($accent, 900) !important;
          .mat-list-text {
            
            &:hover {
              color: mat.get-color-from-palette($accent, 900);
            }
          }
          &:hover,
          &:focus {
            background: rgba(mat.get-color-from-palette($accent, 300), 1) !important;
            color: mat.get-color-from-palette($accent, 900) !important;
          }
        }
        .area-item {
          color: mat.get-color-from-palette($primary, default-contrast);
            &.mat-list-item-disabled{
                background: mat.get-color-from-palette($primary, 700);
                color: map.get($foreground, secondary-text);
            }
        }
      }
    }
  }

}
.case-viewer-right-sidenav{
  width: 239px;
}
.mat-list-base .mat-list-item-disabled{ color: #00000063 !important;}