@use '@angular/material' as mat;
@use 'sass:color';
@use 'sass:map';

@mixin case-actions-theme($theme) {
  $primary: map.get($theme, primary);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .action-points {
    background-color:transparent;
    border-radius: 0px;
    border: 0px solid mat.get-color-from-palette($primary, 100);
    color: none;
  }

  .action-arrows {
    background: transparent 0% 0% no-repeat padding-box;
    opacity: 1;
    color: none;
  }

  .action-points-exit {
    background-color: transparent;
    border: 0px solid mat.get-color-from-palette($primary, default-contrast);
    color: none;
    text-align: center;
  }

  .action-arrows-exit {
    opacity: 1;
    background-color: transparent;
    border: 0px solid mat.get-color-from-palette($primary, default-contrast);
    color: none;
  }
}
