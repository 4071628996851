@use '@angular/material' as mat;
@use 'sass:color';
@use 'sass:map';
@use '../configs/constants';

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $is-dark-theme: map.get($theme, is-dark);
  $nav-background-opacity: if($is-dark-theme, 0.2, 0.03);
  $nav-background-focus-opacity: if($is-dark-theme, 0.25, 0.08);

  app-horizontal-menu {
   .mat-list-base {
     padding-top: 0px !important;
   }

    .mat-list-base .mat-list-item {
      font-size: 12px;
      color: white !important;
    }

    .mat-menu-item {
      background: transparent;
      color: white !important;
    }

  }

}
